<template>
  <div>
    <s-search :queryItems="queryItems" @fetchData="fetchUsers"></s-search>

    <div class="table-button">
      <a-button
        type="plain"
        icon="plus"
        @click="handleAdd('add')"
        v-if="isShowBtns.indexOf('WorkerList-/api/worker/addworker') > -1"
        >新增</a-button
      >
      <a-button
        type="plain"
        icon="cloud-download"
        @click="handleExport('export')"
        v-if="isShowBtns.indexOf('WorkerList-/api/worker/workerexport') > -1"
        >导出</a-button
      >
      <a-button type="plain" icon="redo" @click="$refs.table.refresh(true)"
        >刷新</a-button
      >
      <columns-set
        tableName="WorkerList"
        :columns="columns"
        @changeColumns="changeColumns"
      ></columns-set>
    </div>

    <s-table
      ref="table"
      size="small"
      rowKey="w_id"
      :columns="tableColumns"
      :data="loadData"
      :showPagination="true"
      :scroll="{ x: 1000, y: 'calc(82vh - 150px )' }"
    >
      <span slot="w_face" slot-scope="text, record">
        <a-popover title="人脸信息" trigger="hover" placement="left">
          <div v-if="text.length > 77">
            <img
              :src="text"
              ref="imgname"
              class="imgname"
              @mouseover="showInfo(text)"
              @mouseleave="closeInfo"
            />
          </div>
          <div v-else>暂无图片</div>
          <div slot="content">
            <img :src="Image" v-if="show" ref="imgname1" class="imgname1" />
          </div>
        </a-popover>
        <!-- 添加人脸信息上传时间 -->
        <div class="w_face_createtime">
          {{
            record.w_face_createtime
              ? $Format(record.w_face_createtime, "date")
              : ""
          }}
        </div>
      </span>
      <span slot="serial" slot-scope="text, record, index">
        {{ (pageNo - 1) * pageSize + index + 1 }}
      </span>

      <span slot="w_post" slot-scope="text">
        {{ text }}
      </span>
      <span slot="w_type" slot-scope="text">
        {{ text }}
      </span>
      <span slot="打卡工地数量">
        打卡工地数量
        <a-tooltip>
          <template slot="title"> 最近90天打卡工地数量 </template>
          <a-icon type="question-circle" class="ws_nameTitle_icon" />
        </a-tooltip>
      </span>
      <span
        slot="ws_names"
        slot-scope="item, text"
        class="siteNums"
        @click="goWorkerAttend(text)"
      >
        <!-- <div v-for="(item, index) in text" :key="index">
          {{ item.substring(1, item.length - 1) }}
        </div> -->
        {{ item.length }}
      </span>

      <span slot="action" slot-scope="text, record" class="action">
        <a
          @click="handleAdd('edit', record)"
          v-if="isShowBtns.indexOf('WorkerList-/api/worker/updateworker') > -1"
          >修改</a
        >
        <!-- <a @click="handleExport('disable', record)">禁用</a> -->
        <a-popconfirm
          title="确定删除此条工人信息吗?"
          @confirm="() => onDelete(record)"
          v-if="isShowBtns.indexOf('WorkerList-/api/worker/delworker') > -1"
        >
          <a class="delName">删除</a>
        </a-popconfirm>
      </span>
    </s-table>

    <a-modal
      class="tips-modal"
      title="提示"
      okText="确定"
      cancelText="取消"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @ok="exportOk"
      @cancel="exportCancel"
    >
      <p>
        {{
          modalType === "disable"
            ? "此操作将禁用该员工，是否继续？"
            : "确定导出？"
        }}
      </p>
    </a-modal>

    <a-drawer
      :title="title"
      :width="800"
      placement="right"
      :visible="drawerVisible"
      :destroyOnClose="true"
      @close="onClose"
    >
      <WorkerAdd
        :formType="formType"
        :record="record"
        @emitEdit="emitEdit"
      ></WorkerAdd>
    </a-drawer>
  </div>
</template>

<script>
import { STable, SSearch, ColumnsSet } from "@/components";
import { GetWorkers, Delworker } from "@/api/worker";
import { ExportWorkers } from "@/api/export";
import { urlConfig } from "@/config/config";
import { requestImage } from "@/utils/fileRequest";
import WorkerAdd from "./WorkerAdd.vue";
import moment from "moment";
// 搜索条件
const queryItems = [
  { label: "员工ID", value: "w_id", type: "input" },
  { label: "员工姓名", value: "w_name", type: "input" },
  // { label: "项目经理", value: "w_name_jl", type: "input" },
  {
    label: "人员所属分公司",
    value: "bc_name",
    type: "select",
    selectOption: "CompanyList",
  },
  { label: "岗位", value: "w_post", type: "select", selectOption: "WorkPost" },
  { label: "工种", value: "w_type", type: "select", selectOption: "WorkType" },
  {
    label: "人脸信息",
    value: "face_status",
    type: "select",
    selectOption: "FaceStatus",
    defaultValue: "1",
  },
];

const columns = [
  {
    title: "序号",
    width: "70px",
    scopedSlots: { customRender: "serial" },
    dataIndex: "serial",
    align: "center",
  },
  {
    title: "人脸信息",
    width: "90px",
    dataIndex: "w_face",
    scopedSlots: { customRender: "w_face" },
    // fixed: "right",
    align: "center",
  },
  {
    title: "员工ID",
    width: "90px",
    dataIndex: "w_id",
    align: "center",
  },
  {
    title: "姓名",
    width: "90px",
    dataIndex: "w_name",
    align: "center",
  },
  {
    title: "岗位/工种",
    width: "90px",
    dataIndex: "w_post",
    scopedSlots: { customRender: "w_Post" },
    align: "center",
  },
  {
    title: "性别",
    width: "70px",
    dataIndex: "w_sex",
    align: "center",
  },
  {
    title: "联系方式",
    width: "130px",
    dataIndex: "w_contact",
    align: "center",
  },
  {
    title: "人员所属分公司",
    width: "120px",
    dataIndex: "bc_name",
    align: "center",
  },
  {
    title: "所属项目经理",
    width: "110px",
    dataIndex: "w_jl",
    align: "center",
  },
  {
    // title: "打卡工地数量",
    // width: "300px",
    width: "100px",
    dataIndex: "ws_names",
    scopedSlots: { customRender: "ws_names" },
    slots: { title: "打卡工地数量" },
    // fixed: "right",
    align: "center",
    // tooltip: {
    //   title: "Tooltip with customize icon",
    //   // icon: <a-icon type="question-circle" />,
    // },
  },
  {
    title: "操作",
    width: "100px",
    scopedSlots: { customRender: "action" },
    fixed: "right",
    dataIndex: "action",
    align: "center",
  },
];

export default {
  name: "SiteList",
  components: {
    STable,
    SSearch,
    ColumnsSet,
    WorkerAdd,
  },
  data() {
    this.queryItems = queryItems;
    this.columns = columns;
    return {
      show: false,
      tableColumns: [],
      // 查询参数
      queryParam: {
        face_status: "1",
      },
      //当前页数，当前条数
      pageNo: 1,
      pageSize: 10,
      // 人脸信息图片地址
      pathUrl:
        urlConfig.baseUrl + "/files/picture/snapshot/snapshotpicture?filePath=",
      // modal
      modalType: "",
      visible: false,
      confirmLoading: false,
      Image: "",
      // 新增，编辑
      title: "",
      formType: "",
      record: {},
      drawerVisible: false,
      // 人脸信息上传时间
      w_face_createtime: "",
      isShowBtns: [],
    };
  },
  created() {
    this.tableColumns = columns;
    this.changeColumns();
  },
  mounted() {
    this.isShowBtns = sessionStorage.getItem("isShowBtns").split(",");
  },
  methods: {
    // 自定义表头列
    changeColumns() {
      let ColumnsSet = JSON.parse(localStorage.getItem("WorkerListColumnsSet"));
      let value = ColumnsSet ? ColumnsSet : [];
      let selectColumns = columns.filter((el) => value.includes(el.dataIndex));
      this.tableColumns = selectColumns.length ? selectColumns : columns;
    },
    // 加载数据方法 必须为 Promise 对象
    loadData(parameter) {
      this.pageNo = parameter.pageNo;
      this.pageSize = parameter.pageSize;
      const requestParameters = Object.assign({}, parameter, this.queryParam);
      return GetWorkers(requestParameters).then((res) => {
        res.data.pageNo = res.data.page;
        res.data.totalCount = res.data.sum;
        // 处理数据
        const url = this.pathUrl;
        let data = res.data.data;
        // console.log(res.data.data);
        data.forEach((el, i) => {
          let face = el.w_face;
          el.w_face = url + face;
        });
        return res.data;
      });
    },
    fetchUsers(value) {
      this.queryParam = value;
      this.$refs.table.refresh(true);
    },
    handleExport(type) {
      this.modalType = "";
      this.visible = true;
      if (type === "disable") {
        this.modalType = type;
      }
    },
    exportOk() {
      this.confirmLoading = true;
      if (this.modalType === "disable") {
        this.visible = false;
        this.confirmLoading = false;
        // DisableWorker().then(res => {})
      } else {
        const params = {
          // ws_status: "",
          // d_status: "",
          // ws_bind_status: "",
          w_name: this.queryParam.w_name,
          w_name_jl: this.queryParam.w_name_jl,
          w_id: this.queryParam.w_id,
          w_type: this.queryParam.w_type,
          bc_name: this.queryParam.bc_name,
          w_post: this.queryParam.w_post,
          face_status: this.queryParam.face_status,
        };
        ExportWorkers(params).then((res) => {
          var blob = res;
          var reader = new FileReader();
          reader.readAsDataURL(blob); // 转换为base64，可以直接放入a标签href
          reader.onload = function (e) {
            // 转换完成，创建一个a标签用于下载
            var a = document.createElement("a");
            a.download = "人员列表.xls";
            a.href = e.target.result;
            // $("body").append(a);    // 修复firefox中无法触发click
            a.click();
            a.remove();
          };
          this.visible = false;
          this.confirmLoading = false;
        });
      }
    },
    exportCancel() {
      this.visible = false;
    },
    showInfo(url) {
      this.Image = url;
      this.show = true;
    },
    closeInfo() {
      this.Image = "";
      this.show = false;
    },
    handleAdd(type, record) {
      this.drawerVisible = true;
      this.formType = type;
      if (record) {
        this.record = record;
      }
    },
    onClose() {
      this.drawerVisible = false;
    },
    emitEdit() {
      this.drawerVisible = false;
      this.$refs.table.refresh();
    },
    goWorkerAttend(text) {
      let time = +new Date();
      let ninety_ago = time - 1000 * 60 * 60 * 24 * 90;
      // this.$router.push({
      //   path: "/WorkerAttend",
      //   query: {
      //     w_name: text.w_name,
      //     start_time: Math.trunc(ninety_ago / 1000),
      //     end_time: Math.trunc(time / 1000),
      //   },
      // });
      // console.log(item, text);
      // console.log(this.$Format(Math.trunc(time / 1000)));
      // console.log(this.$Format(Math.trunc(ninety_ago / 1000)));
      // 打开新窗口，跳转到人员考勤，携带参数
      // localStorage.setItem("path", "WorkerAttend");
      // const { href } = this.$router.resolve({
      //   path: "/WorkerAttend",
      //   query: {
      //     w_name: text.w_name,
      //     start_time: Math.trunc(ninety_ago / 1000),
      //     end_time: Math.trunc(time / 1000),
      //   },
      // });
      // window.open(href, "_blank");
      this.$store.commit("SET_CURRENT", "WorkerAttend");
      this.$router.push({
        path: "/WorkerAttend",
        query: {
          w_name: text.w_name,
          start_time: Math.trunc(ninety_ago / 1000),
          end_time: Math.trunc(time / 1000),
        },
      });
    },
    // 删除工人信息
    onDelete(record) {
      // console.log(record);
      const param = {
        w_id: record.w_id,
      };
      const orderParam = ["w_id"];
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      return Delworker(params).then((res) => {
        if (res.msg == "failed") {
          this.$message.error(res.data);
        } else {
          this.$message.success(res.data);
          this.$emit("emitEdit");
        }
        this.$refs.table.refresh();
      });
    },
  },
};
</script>
<style lang="less" scoped>
// 表头居中显示
/deep/.ant-table-thead > tr > th {
  text-align: center;
}
.imgname {
  width: 50px;
  height: 70px;
  object-fit: cover;
}
.imgname1 {
  width: 300px;
  height: 420px;
  object-fit: cover;
}
// .imgname:hover{
//   width: 150px;
//   height: 170px;
//   position: fixed;
//   z-index: 10;
//   }
.action a {
  margin-right: 10px;
}
.check-img {
  width: 100%;
  height: 80px;
  vertical-align: top;
}
.w_face_createtime {
  // width: 90px;
  // margin-left: -10px;
  text-align: center;
}
.siteNums {
  color: blue;
  cursor: pointer;
}
.ws_nameTitle_icon {
  cursor: pointer;
}
// ::v-deep .ant-table-scroll {
//   height: calc(100vh - 170px);
//   overflow: auto scroll;
// }
.delName {
  color: red;
}
</style>
