<template>
  <div class="modal-content">
    <a-form-model
      ref="ruleForm"
      :model="form"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
      :rules="rules"
    >
      <a-form-model-item
        v-for="item in formOption"
        :key="item.value"
        :label="item.label"
        :prop="item.value"
        :ref="item.value"
      >
        <!-- 输入 -->
        <a-input
          v-if="item.type === 'input'"
          v-model="form[item.value]"
          :placeholder="'请输入' + item.label"
        ></a-input>
        <!-- 搜索    @change="change()" -->
        <a-select
          v-if="item.type === 'select'"
          v-model="form[item.value]"
          :placeholder="'请选择' + item.label"
          @change="$forceUpdate()"
        >
          <a-select-option
            v-for="opt in item.options"
            :key="opt.value"
            :value="opt.value"
            >{{ opt.label }}</a-select-option
          >
        </a-select>
        <!-- 远程搜索 -->
        <a-select
          v-if="item.type === 'remoteSearch'"
          show-search
          mode="multiple"
          v-model="form[item.value]"
          :placeholder="'请输入查询' + item.label"
          @change="$forceUpdate()"
        >
          <!--     @change="$forceUpdate()"   :default-active-first-option="false"
          :show-arrow="true"
          :filter-option="false"
          :not-found-content="null"
          @search="handleSearch($event, item.method, item.value)"-->

          <a-select-option
            v-for="opt in item.options"
            :key="opt.value"
            :value="opt.value"
          >
            {{ opt.label }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
    </a-form-model>
    <div class="title">人脸信息:</div>
    <div class="clearfix">
      <!--  action="https://ai1.4all.cn/files/api/file/upload" -->
      <a-upload
        list-type="picture-card"
        :file-list="fileList"
        @change="handleChange"
        @preview="handlePreview"
        name="file"
        :customRequest="customRequest"
      >
        <div v-if="fileList.length < 1">
          <a-icon type="plus" />
          <div class="ant-upload-text">Upload</div>
        </div>
      </a-upload>
      <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
        <img alt="example" style="width: 100%" :src="previewImage" />
      </a-modal>
    </div>

    <div style="margin-top: 20px; text-align: right">
      <a-button @click="cancel()">返回</a-button>
      <a-button @click="submit()" type="primary" style="margin-left: 16px"
        >确认</a-button
      >
    </div>
  </div>
</template>
<script>
import { SelectOptions } from "@/config/SelectOptions.js";
import {
  AddWorker,
  Updateworker,
  GetWorkerType,
  GetWorkerPost,
} from "@/api/worker";
import { GetCompanies } from "@/api/device";
import { number } from "echarts";

const formOption = [
  {
    label: "员工ID",
    value: "w_only",
    type: "input",
  },
  {
    label: "姓名",
    value: "w_name",
    type: "input",
  },
  {
    label: "性别",
    value: "w_sex",
    type: "select",
    options: SelectOptions.WorkerSex,
  },
  {
    label: "岗位",
    value: "w_post_only",
    type: "select",
    options: [],
    method: "WorkerAddPost",
  },
  {
    label: "工种",
    value: "w_type_only",
    type: "select",
    options: [],
    method: "WorkerAddType",
  },
  {
    label: "分公司名称",
    value: "bc_ids",
    type: "remoteSearch",
    options: [],
    method: "GetCompanies",
  },
  {
    label: "联系方式",
    value: "w_contact",
    type: "input",
  },
  {
    label: "所属项目经理",
    value: "w_jl_only",
    type: "input",
  },
];
export default {
  name: "WorkerAdd",
  props: {
    formType: {
      type: String,
      default: "add",
    },
    record: {
      type: Object,
    },
  },
  data() {
    return {
      formOption: [],
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      form: {
        w_only: "",
        w_name: "",
        w_sex: "",
        w_post_only: "",
        w_type_only: "",
        bc_ids: "",
        w_contact: "",
        w_jl_only: "",
        w_face: "",
      },
      addVerify: [
        "w_only",
        "w_name",
        "w_type_only",
        "w_contact",
        "w_sex",
        "bc_ids",
        "w_post_only",
        "w_jl_only",
      ],
      editVerify: [
        "w_id",
        "w_only",
        "w_name",
        "w_type_only",
        "w_contact",
        "w_sex",
        "bc_ids",
        "w_post_only",
        "w_jl_only",
      ],
      rules: {
        w_name: [
          {
            required: true,
            message: "请输入员工姓名",
            trigger: "blur",
          },
          // {
          //   min: 2,
          //   max: 5,
          //   message: "Length should be 3 to 5",
          //   trigger: "blur",
          // },
        ],
        w_only: [
          {
            required: true,
            message: "请输入员工ID",
            trigger: "blur",
          },
        ],
        w_sex: [{ required: true, message: "请选择员工性别", trigger: "blur" }],
        w_post_only: [
          {
            required: true,
            message: "请选择员工岗位",
            trigger: "change",
          },
        ],
        bc_ids: [
          // {
          //   required: true,
          //   message: "请至少选择一个分公司",
          //   // trigger: "change",
          //   trigger: "blur",
          //   type: "any",
          //   // transform: (value, e) => {
          //   //   console.log(value, e);
          //   //   console.log(typeof value);
          //   // },
          // },
          // {
          //   // validator: this.validatePhone,
          // },
          // {
          //   min: 1,
          //   // // // max: 5,
          //   message: "请至少选择一个分公司",
          //   // trigger: "blur",
          //   // type: "number",
          // },
        ],
        w_contact: [
          {
            required: true,
            message: "请输入正确手机号",
            trigger: "blur",
          },
          {
            min: 11,
            max: 11,
            message: "请输入11位手机号",
            trigger: "blur",
          },
        ],
      },
      previewVisible: false,
      previewImage: "",
      fileList: [],
    };
  },
  // async
  created() {
    this.formOption = formOption;
    // console.log(this.formOption);

    if (this.formType === "edit") {
      this.form = JSON.parse(JSON.stringify(this.record));
      this.form.w_post_only = this.form.w_post;
      this.form.w_only = this.form.w_id;
      this.fileList.push({
        url: this.form.w_face,
        uid: "-1",
        name: "image.png",
        status: "done",
      });
      // this.form.bc_ids = this.form.bc_name;
    }
    this.fetchCompany();
  },
  methods: {
    validatePhone(rule, value, callback) {
      // rule.type = "array";
      console.log("value", value);
      console.log("rule", rule);
      // console.log("callback", callback);
      if (value.length > 0) {
        callback();
      } else {
        callback("请至少选择一个分公司!");
      }
    },
    fetchCompany() {
      let data = [];
      let that = this;
      let bc_idsNewList = [];
      GetCompanies().then((res) => {
        res.data.forEach((el) => {
          data.push({
            label: el.bc_name,
            value: el.bc_id,
          });
          // console.log(
          //   el.bc_name.replace(/(^\s*)|(\s*$)/g, ""),
          //   that.form.bc_name.replace(/(^\s*)|(\s*$)/g, "")
          // );
          if (that.form.bc_name) {
            if (
              el.bc_name.replace(/(^\s*)|(\s*$)/g, "") ==
              that.form.bc_name.replace(/(^\s*)|(\s*$)/g, "")
            ) {
              that.form.bc_ids = el.bc_id;
            } else if (that.form.bc_name.split(" ").length > 1) {
              let bc_nameList = that.form.bc_name.split(" ");
              bc_nameList.forEach((item) => {
                if (item == el.bc_name.replace(/(^\s*)|(\s*$)/g, "")) {
                  bc_idsNewList.push(el.bc_id);
                  that.form.bc_ids = bc_idsNewList;
                  // that.form.bc_ids = [6, 20];

                  // that.form.bc_ids = bc_idsNewList.toString();
                }
              });
            }
          }
        });
        // console.log(" that.form.bc_ids", that.form.bc_ids);
        let index = null;
        this.formOption.forEach((el, i) => {
          if (el.value === "bc_ids") {
            index = i;
          }
        });
        this.formOption[index].options = data;
      });
      let dataType = [];
      GetWorkerType().then((res) => {
        res.data.forEach((el) => {
          dataType.push({
            label: el.type_name,
            value: el.type_id,
          });
        });
        let index = null;
        this.formOption.forEach((el, i) => {
          if (el.value === "w_type_only") {
            index = i;
          }
        });
        this.formOption[index].options = dataType;
      });
      let dataPost = [];
      GetWorkerPost().then((res) => {
        res.data.forEach((el) => {
          dataPost.push({
            label: el.post_name,
            value: el.post_id,
          });
        });
        let index = null;
        this.formOption.forEach((el, i) => {
          if (el.value === "w_post_only") {
            index = i;
          }
        });
        dataPost.push({
          label: "其他",
          value: "qita",
        });
        this.formOption[index].options = dataPost;
      });
    },
    handleChange(el) {
      console.log("00000", el);
      // this.form.bc_ids.push(el);
      // console.log("this.form.bc_ids", this.form.bc_ids);
    },
    handleSearch(val, method, key) {
      // console.log(val, method, key);
    },
    cancel() {
      this.$emit("emitEdit");
    },
    submit() {
      console.log("submit", this.form);
      // 提交的时候，这是form里的值，也会被改变,也是有值的，也是数组
      // this.form = JSON.parse(JSON.stringify(this.form));
      this.form = JSON.parse(JSON.stringify(this.form));
      this.form.bc_ids = this.form.bc_ids
        ? JSON.parse(JSON.stringify(this.form.bc_ids))
        : "";
      const data = new FormData();
      let file = this.fileList.length > 0 ? this.fileList[0].originFileObj : "";
      // // 上传的文件，就是我们熟悉的HTML表单的input[type=file]的值
      data.append("face", file);
      // console.log("data", data, file);
      // console.log("arr---", JSON.parse(JSON.stringify(this.form)));
      // this.form.bc_ids = this.form.bc_ids.toString();
      // console.log("校验", this.$refs.ruleForm);
      setTimeout(() => {
        this.$refs.ruleForm.validate((valid) => {
          // console.log("valid", valid);
          // console.log(this.form, "9999");

          if (valid) {
            // alert("submit!");
            let req = null;
            this.form.bc_ids = this.form.bc_ids.toString();
            // console.log("this.form.bc_ids000", this.form.bc_ids);
            if (this.formType === "add") {
              const orderParam = this.$order(this.form, this.addVerify);
              const params = {
                ...orderParam,
                verify: this.$verify(orderParam, this.addVerify),
              };

              req = AddWorker(params, data);
            } else {
              const orderParam = this.$order(this.form, this.editVerify);
              const params = {
                ...orderParam,
                verify: this.$verify(orderParam, this.editVerify),
              };
              // console.log("upd-params", params, data);
              req = Updateworker(params, data);
            }
            // this.$emit("emitEdit");
            req.then((res) => {
              if (res.msg == "failed") {
                this.$message.error(res.data);
              } else {
                this.$message.success(res.data);
                this.$emit("emitEdit");
              }
            });
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      }, 100);
    },
    handleCancel() {
      this.previewVisible = false;
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      // console.log('this.previewImage--this.previewImage ', this.previewImage)

      this.previewVisible = true;
    },
    handleChange({ fileList }) {
      this.fileList = fileList;
      // console.log('handleChange--this.fileList', this.fileList)
    },
    customRequest(data) {
      console.log("customRequest", data);
      // https://ai1.4all.cn/files/api/file/upload
      setTimeout(() => {
        console.log("fileList", this.fileList);
        this.uploadLoading = false;
        data.onSuccess("");
      }, 10);
    },
  },
};
</script>
<style scoped lang="less">
.title {
  margin-left: 70px;
  font-weight: 700;
}
.clearfix {
  margin-left: 150px;
}
</style>
